@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Work Sans", sans-serif;
  overflow-y: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track-piece {
  background-color: #0a141e;
}

::-webkit-scrollbar-thumb {
  background-color: #004247;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #49ffa7;
}

/* PAGE STYLES */

.px-site {
  @apply px-8 lg:px-12 xl:px-16;
}

.pl-site {
  @apply pl-8 lg:pl-12 xl:pl-16;
}

.pr-site {
  @apply pr-8 lg:pr-12 xl:pr-16;
}

.py-site {
  @apply py-8 lg:py-12 xl:py-16;
}

.pt-site {
  @apply pt-8 lg:pt-12 xl:pt-16;
}

.pb-site {
  @apply pb-8 lg:pb-12 xl:pb-16;
}

.px-site-mobile {
  @apply px-0 md:px-8 lg:px-12 xl:px-16;
}

.px-content {
  @apply px-0 lg:px-12 xl:px-28;
}

.pl-content {
  @apply pl-0 lg:pl-12 xl:pl-28;
}

.pr-content {
  @apply pr-0 lg:pr-12 xl:pr-28;
}

/* PAGE TITLE STYLES */

.page-title-content-offset {
  @apply md:-mt-12;
}

.page-title-content-offset-large {
  @apply md:-mt-32;
}

.page-title-content-offset-mobile {
  @apply -mt-12;
}

.page-title-content-offset-mobile-large {
  @apply -mt-32;
}

.pt-page-title {
  @apply pt-32 2xl:pt-36;
}

.pb-page-title {
  @apply pb-32 2xl:pb-36;
}

.py-page-title {
  @apply pt-page-title pb-16 md:pb-32 2xl:pb-36;
}

.py-page-title-large-offset {
  @apply pt-page-title pb-16 md:pb-48;
}

.py-page-title-small-offset {
  @apply pt-page-title pb-16 md:pb-28;
}

.py-page-title-no-offset {
  @apply pt-page-title pb-16;
}

.pt-page-title-row {
  @apply pt-16 md:pt-32 2xl:pt-36;
}

.pb-page-title-row {
  @apply pb-16 md:pb-32 2xl:pb-36;
}

.py-page-title-row {
  @apply pt-page-title-row pb-page-title-row;
}

.py-page-title-row-small-offset {
  @apply pt-page-title-row pb-28 md:pb-36 2xl:pb-48;
}

.page-title-breadcrumb {
  @apply text-accent hover:text-white transition-colors text-sm font-medium px-4 first:pl-0 last:pr-0 leading-none;
}

/* BUTTON STYLES */

.rounded-button-generic {
  @apply rounded-full border transition;
}

.rounded-button-label {
  @apply text-[10px] text-center font-semibold tracking-widest uppercase px-10 py-4;
}

.rounded-button-label-lg {
  @apply text-sm text-center font-medium tracking-widest uppercase px-10 py-4;
}

.rounded-button-accent-color {
  @apply text-white hover:text-primary hover:bg-accent border-accent disabled:text-white/50 disabled:border-black/10;
}

.rounded-button-accent-reverse-color {
  @apply text-primary hover:text-accent bg-accent hover:bg-transparent border-accent disabled:bg-black/5 disabled:text-white/50 disabled:border-black/10;
}

.rounded-button-primary-color {
  @apply text-primary hover:text-white hover:bg-primary border-primary disabled:text-white/50 disabled:border-black/10;
}

.rounded-button-primary-reverse-color {
  @apply text-white hover:text-primary bg-primary hover:bg-transparent border-primary disabled:text-white/50 disabled:hover:text-primary/50;
}

.rounded-button-white-color {
  @apply text-white hover:text-primary hover:bg-accent border-white/50 hover:border-accent disabled:text-white/50 disabled:border-black/10;
}

.rounded-button-premium-color {
  @apply text-white hover:text-premium bg-premium hover:bg-transparent border-premium disabled:text-white/50 disabled:border-black/10;
}

.rounded-button-premium-reverse-color {
  @apply text-premium hover:text-premium-light bg-transparent hover:bg-premium border-premium disabled:text-white/50 disabled:border-black/10;
}



.rounded-button-accent {
  @apply rounded-button-generic rounded-button-label rounded-button-accent-color;
}

.rounded-button-accent-reverse {
  @apply rounded-button-generic rounded-button-label rounded-button-accent-reverse-color;
}

.rounded-button-primary {
  @apply rounded-button-generic rounded-button-label rounded-button-primary-color;
}

.rounded-button-primary-reverse {
  @apply rounded-button-generic rounded-button-label rounded-button-primary-reverse-color;
}

.rounded-button-white {
  @apply rounded-button-generic rounded-button-label rounded-button-white-color;
}

.rounded-button-premium {
  @apply rounded-button-generic rounded-button-label rounded-button-premium-color;
}

.rounded-button-premium-lg {
  @apply rounded-button-generic rounded-button-label-lg rounded-button-premium-color;
}


.rounded-button-premium-reverse {
  @apply rounded-button-generic rounded-button-label rounded-button-premium-reverse-color;
}



.rounded-button-with-icon {
  @apply pl-3 pr-6 py-3 flex flex-row items-center gap-3;
}

.rounded-button-icon {
  @apply w-6 aspect-square rounded-full border border-primary flex items-center justify-center transition-colors;
}

.rounded-button-icon-primary {
  @apply rounded-button-icon border-primary group-hover:border-accent group-hover:bg-accent group-hover:text-primary;
}


.rounded-icon-button-generic {
  @apply rounded-button-generic aspect-square flex items-center justify-center p-4;
}

.rounded-icon-button-accent {
  @apply rounded-icon-button-generic rounded-button-accent-color;
}


.text-button-generic {
  @apply flex items-center gap-1 text-sm transition-colors font-semibold;
}

.text-button-accent {
  @apply text-button-generic text-accent hover:text-white;
}

.text-button-premium {
  @apply text-button-generic text-premium hover:text-premium-dark uppercase tracking-widest;
}


.icon-button-generic {
  @apply font-semibold uppercase tracking-wider flex items-center gap-1 transition;
}

.icon-button-white {
  @apply icon-button-generic text-white hover:text-accent text-sm;
}

.icon-button-premium {
  @apply icon-button-generic text-premium hover:text-premium-dark text-sm;
}

.icon-button-premium-small {
  @apply icon-button-generic text-premium hover:text-premium-dark text-xs;
}

.icon-button-secondary {
  @apply icon-button-generic text-secondary hover:text-primary text-sm;
}

/* INPUT STYLES */

.generic-select {
  @apply text-white px-0 sm:px-4 py-4 bg-transparent border-b border-black/10 outline-none text-sm font-semibold uppercase;
}

.generic-select-option {
  @apply text-white/75 bg-secondary normal-case;
}

.generic-light-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat; */
  /* background-position: calc(100% - 0.75rem) center !important; */
  @apply text-white py-4 bg-transparent border-b border-white/10 outline-none text-sm font-semibold uppercase;
}

.light-select {
  @apply text-primary border bg-white border-neutral-100 bg-transparent px-4 outline-none text-sm font-bold;
}

.line-input-generic {
  @apply px-0 sm:px-4 py-4 bg-transparent border-b outline-none transition;
}

.line-input-generic-small {
  @apply text-sm px-0 py-1.5 bg-transparent border-b outline-none transition;
}

.line-input-generic-variable {
  @apply text-sm sm:text-base px-0 sm:px-4 py-2 sm:py-4 bg-transparent border-b outline-none transition;
}

.line-input-text-uppercase {
  @apply text-sm font-semibold uppercase;
}

.line-input-white {
  @apply line-input-generic line-input-text-uppercase text-white border-black/10;
}

.line-input-white-dark-background {
  @apply line-input-generic line-input-text-uppercase text-white border-white/10 focus:border-white/50;
}

.line-input-premium-generic {
  @apply text-premium placeholder:text-premium/50 placeholder:focus:text-premium placeholder:font-normal border-premium/50 focus:border-premium font-medium;
}

.line-input-premium {
  @apply line-input-generic line-input-premium-generic;
}

.line-input-premium-small {
  @apply line-input-generic-small line-input-premium-generic;
}

.line-input-premium-variable {
  @apply line-input-generic-variable line-input-premium-generic;
}

/* FLOATING CARD */

.floating-card-generic {
  @apply bg-white shadow-3xl text-primary;
}

.floating-card {
  @apply floating-card-generic overflow-hidden;
}

.floating-card-overflow {
  @apply floating-card-generic overflow-visible;
}

.floating-card-title {
  @apply text-xl text-primary font-medium;
}

.floating-card-padding {
  @apply p-8;
}

.floating-card-grid-gap {
  @apply gap-8 xl:gap-12;
}

.floating-card-grid-gap-small {
  @apply gap-8;
}

.floating-card-grid-gap-smaller {
  @apply gap-6;
}

.floating-card-grid-gap-smallest {
  @apply gap-4;
}

/* CONTENT TEXT */

.content-text {
  /* @apply dark:text-white; */
}

.content-text h1 {
  @apply text-4xl md:text-5xl font-bold;
}

.content-text h2 {
  @apply text-xl md:text-3xl font-semibold;
}

.content-text h3 {
  @apply text-xl md:text-2xl font-medium;
}

.content-text h4 {
  @apply text-lg font-medium;
}

.content-text h5 {
  @apply text-sm;
}

.content-text h6 {
  @apply text-xs;
}

.content-text h1,
.content-text h2,
.content-text h3,
.content-text h4,
.content-text h5,
.content-text h6,
.content-text ul {
  @apply my-6 first:mt-0 last:mb-0;
}

.content-text p {
  @apply mb-3 first:mt-0 last:mb-0;
}

.content-text ul {
  @apply text-sm leading-6 list-disc list-inside;
}

.content-text a {
  @apply bg-primary-light/10 rounded px-1 py-0.5 font-medium hover:bg-primary-light/20 transition after:opacity-75 after:content-['\00a0↴'] after:h-full;
}

.content-text table td,
th {
  @apply border border-neutral-100 p-4 sm:p-6;
}

/* ETC */

.disc-list ul {
  @apply list-disc list-inside;
}

.paragraph-gap p {
  @apply mb-3 first:mt-0 last:mb-0;
}

.paragraph-gap-lg p {
  @apply mb-6 first:mt-0 last:mb-0;
}